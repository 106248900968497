import { useEffect, useState } from "react";
import SplashScreen from "./components/SplashScreen";
import WorkInProgreePage from "./pages/WorkInProgress";

import classes from './index.module.scss';

const {
  app
} = classes;

const App = ({}) => {

  const [splashScreen, turnSpash] = useState("on");
  const splashScreenTime = 3000;

  useEffect(() => {
    const timeout = setTimeout(() => {
      turnSpash("off");
      clearTimeout(timeout);
    }, splashScreenTime);
  }, []);

  return (
    splashScreen === "on" ? <SplashScreen timeOut={splashScreenTime} /> : (
      <div className={app}>
        <WorkInProgreePage />
      </div>
    )
  );
}

export default App;
