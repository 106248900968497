import TargetIcon from "./vectors/target"

const Icon = (props) => {

    const {name = "none", className = ""} = props;

    return (
        <>
            {
                name === "none" ? (
                    <div className={"" + (className ? " " + className : "")}>

                    </div>
                ) : ""
            }
            {
                name === "target" ? (
                    <TargetIcon {...props} />
                ) : ""
            }
        </>
    );
}

export default Icon;