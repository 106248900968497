import styles from "./index.module.scss";

const { image } = styles;

function Picture({ className, fileName, alt, lazy }) {
   return (
      <picture>
         <source
            media="(min-width: 920px)"
            srcSet={fileName ? `/assets/imgs/${fileName}.webp 1x, /assets/imgs/${fileName}@2x.webp 2x` : ""}
            type="image/webp"
         />
         <source
            media="(min-width: 500px)"
            srcSet={fileName ? `/assets/imgs/${fileName}.webp 1x, /assets/imgs/${fileName}@2x.webp 2x` : ""}
            type="image/webp"
         />
         <source
            media="(min-width: 920px)"
            srcSet={fileName ? `/assets/imgs/${fileName}.jpg 1x, /assets/imgs/${fileName}@2x.jpg 2x` : ""}
         />
         <source
            media="(min-width: 500px)"
            srcSet={fileName ? `/assets/imgs/${fileName}.jpg 1x, /assets/imgs/${fileName}@2x.jpg 2x` : ""}
         />
         <source
            srcSet={
               fileName ? `/assets/imgs/${fileName}.webp 1x, /assets/imgs/${fileName}@2x.webp 2x` : ""
            }
            type="image/webp"
         />
         <img
            className={className ? className : image}
            src={fileName ? `/assets/imgs/${fileName}.jpg` : ""}
            alt={alt ? alt : ""}
            srcSet={
               fileName ? `/assets/imgs/${fileName}.jpg 1x, /assets/imgs/${fileName}@2x.jpg 2x` : ""
            }
            loading={lazy ? "lazy" : ""}
            decoding="async"
         />
      </picture>
   );
}

export default Picture;
