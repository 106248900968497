import { useState } from "react";
import Block from "../../components/Block";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Picture from "../../components/Picture";
import classes from "./styles.module.scss";

const {
    workInProgressPage,
    workInProgressPage__location,
    workInProgressPage__locationIcon,
    workInProgressPage__locationText,
    workInProgressPage__title,
    workInProgressPage__description,
    workInProgressPage__food,
    workInProgressPage__block,
    workInProgressPage__blockText,
    workInProgressPage__button,
    workInProgressPage__link,
    workInProgressPage__copyright
} = classes

const WorkInProgreePage = ({}) => {

    const [location, setLocation] = useState("Budva");

    return (
        <div className={workInProgressPage}>
            <div className={workInProgressPage__location}>
                <Icon name="target" className={workInProgressPage__locationIcon} />
                <p className={workInProgressPage__locationText}>{location}</p>
            </div>

            <h2 className={workInProgressPage__title}>jeda-tuda</h2>
            <p className={workInProgressPage__description}>Food delivery</p>

            <Picture className={workInProgressPage__food} fileName="food-set" alt="" />

            <Block className={workInProgressPage__block}>
                <p className={workInProgressPage__blockText}>
                    Dear friends, we are glad to inform you about the beginning of the development of the Jeda-Tuda service! Our team is working on creating a food delivery system that will allow you to easily and quickly order your favorite food from the best restaurants in the city.<br />
                    <br />
                    Our service will offer you a wide choice of food and restaurants, as well as fast and reliable delivery. We are working on technologies that will allow us to ensure the high quality and safety of our service.<br />
                    <br />
                    You can follow the launch of the service on our Instagram page.
                </p>

                <Button className={workInProgressPage__button} href="https://www.instagram.com/jeda_tuda/">
                    Go to Instagram
                </Button>
                
                <p className={workInProgressPage__blockText}>
                    For cooperation for restaurants, cafes and bars, please contact <a href="maito:coop@jeda-tuda.com" className={workInProgressPage__link}>coop@jeda-tuda.com</a>
                </p>
            </Block>

            <p className={workInProgressPage__copyright}>
                MoosYo LLC © 2022<br />
                85310 Montenegro, Budva<br />
                <a href="maito:feedback@jeda-tuda.com" className={workInProgressPage__link}>feedback@jeda-tuda.com</a>
            </p>
        </div>
    );
}

export default WorkInProgreePage;