import classes from "./styles.module.scss";

const {
    button
} = classes;

const Button = ({
    className = "",
    href = "",
    children = (() => "")(),
    type = "button",
    target = "_self"
}) => {


    return (
        href ? <a href={href} target={target} className={button + (className ? " " + className : "")}>
            {children}
        </a>
        : <button className={button + (className ? " " + className : "")} type={type}>
            {children}
        </button>
    );
}

export default Button;