import classes from "./styles.module.scss";

import Picture from "../Picture";
import MoosYoLogo from "../../assets/vectors/moosyo-logo";
import { useEffect, useState } from "react";

const {
    splashScreen,
    splashScreen__background,
    splashScreen__content,
    splashScreen__title,
    splashScreen__description,
    splashScreen__logo,
} = classes;

const SplashScreen = ({timeOut = 3000}) => {

    const [showingState, setShowingState] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowingState(false);
            clearTimeout(timeout);
        }, timeOut - 400);
    }, []);

    return (
        <div className={splashScreen} style={{opacity: showingState ? 1 : 0}} >
            <Picture fileName={"loading-background"} alt="" className={splashScreen__background} />

            <div className={splashScreen__content}>
                <h2 className={splashScreen__title}>jeda-tuda</h2>
                <p className={splashScreen__description}>Food delivery</p>

                <a href="https://moosyo.com/" target={"_blank"} rel="noopenner noreferrer">
                    <MoosYoLogo className={splashScreen__logo} />
                </a>
            </div>
        </div>
    );
}

export default SplashScreen;