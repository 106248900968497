import classes from "./styles.module.scss";

const {
    block
} = classes;

const Block = ({className = "", children = (() => "")()}) => {

    return (
        <div className={block + (className ? " " + className : "")}>
            {children}
        </div>
    );
}

export default Block;